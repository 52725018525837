<template>
		<a :href="path" target="_blank">
			<button class="button__container">
				{{ text }}
						<slot />
				</button>
		</a>
</template>

<script>
export default {
	props: {
		text: {
			type: String,
			default: ''
		},
				path: {
						type: String,
			default: ''
				}
	}
}
</script>

<style scoped lang="scss">
@import '../assets/styles/variables.scss';
.button {
	&__container {
				width: max-content;
				border: 0;
				padding: .9rem;
				border-radius: 8px;
				font-size: 1rem;

				display: flex;
				align-items: center;
				justify-content: center;

				gap: 0.5rem;

				cursor: pointer;

				background: #a48fff48;
				color: $white-10;

				&:hover {
						opacity: .9;
				}
		}
}

@media screen and (max-width: 740px) {
		.button__container {
				font-size: .8rem;
		}
}
</style>