<template>
    <header :class="{'navbar__scrolled': scrollPosition}">
      <nav>
        <router-link v-if="!mobile" to="/" class="navbar__branding">
          @hosanabarcelos
        </router-link>
        <ul v-show="!mobile" class="navbar__navigation">
          <li>
            <router-link
              to="/about"
              class="navbar__link"
              :class="{ 'navbar__link-active': isRouteActive('/about') }"
            >
              {{ aboutTitle }}
            </router-link>
            <router-link
              to="/experience"
              class="navbar__link"
              :class="{ 'navbar__link-active': isRouteActive('/experience') }"
            >
              {{ experienceTitle }}
            </router-link>
            <!-- <router-link
              to="/blog"
              class="navbar__link"
              :class="{ 'navbar__link-active': isRouteActive('/blog') }"
            >
              Blog
            </router-link> -->
            <router-link
              to="/feedbacks"
              class="navbar__link"
              :class="{ 'navbar__link-active': isRouteActive('/feedbacks') }"
            >
              {{ recomendationsTitle }}
            </router-link>
            <router-link
              to="/contact"
              class="navbar__link"
              :class="{ 'navbar__link-active': isRouteActive('/contact') }"
            >
              {{ contactTitle }}
            </router-link>
          </li>
        </ul>
        <div class="navbar__icon-mobile" v-if="mobile">
          <div @click="toggleMobile" :class="{'icon-active': mobileNav}">
            <div class="bar" :class="{ 'bar1': mobileNav }"></div>
            <div class="bar" :class="{ 'bar2': mobileNav }"></div>
            <div class="bar" :class="{ 'bar3': mobileNav }"></div>
          </div>
        </div>
        <transition name="mobile-nav">
          <ul v-show="mobileNav" class="navbar__dropdown-navigation">
            <li @click="toggleMobile">
                <router-link
              :to="{ path: '/', exact: true }"
              class="navbar__link"
              :class="{ 'navbar__link-active': isRouteActive('/') }"
            >
              {{ initTitle }}
            </router-link>
              <router-link
                to="/about"
                class="navbar__link"
                :class="{ 'navbar__link-active': isRouteActive('/about') }"
              >
                {{ aboutTitle }}
              </router-link>
              <router-link
                to="/experience"
                class="navbar__link"
                :class="{ 'navbar__link-active': isRouteActive('/experience') }"
              >
                {{ experienceTitle }}
              </router-link>
              <!-- <router-link
                to="/blog"
                class="navbar__link"
                :class="{ 'navbar__link-active': isRouteActive('/blog') }"
              >
                Blog
              </router-link> -->
              <router-link
                to="/feedbacks"
                class="navbar__link"
                :class="{ 'navbar__link-active': isRouteActive('/feedbacks') }"
              >
                {{ recomendationsTitle }}
              </router-link>
              <router-link
                to="/contact"
                class="navbar__link"
                :class="{ 'navbar__link-active': isRouteActive('/contact') }"
              >
                {{ contactTitle }}
              </router-link>
            </li>
          </ul>
        </transition>
      </nav>
    </header>
  </template>


<script>
import { mapState, mapMutations } from 'vuex';

export default {
  props: {
    language: Boolean,
  },
  data() {
    return {
      initTitle: 'Home',
      aboutTitle: 'About',
      contactTitle: 'Contact',
      recomendationsTitle: 'Feedbacks',
      experienceTitle: 'Experience',

      scrollPosition: null,
      mobile: null,
      mobileNav: null,
      windowWidth: null,
    };
  },

  created() {
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();
  },

  mounted() {
    if (!this.mobile) {
      window.addEventListener('scroll', this.updateScroll);
    }
  },

  computed: {
    ...mapState(['language']),
  },

  methods: {
    ...mapMutations(['setLanguage']),

    toggleMobile() {
      this.mobileNav = !this.mobileNav;
    },

    switchLanguage() {
      this.setLanguage(!this.language);
    },

    isRouteActive(route) {
      return this.$route.path === route;
    },

    updateScroll() {
      const scrollNav = window.scrollY;
      if (scrollNav > 50) {
        this.scrollPosition = true;
        return;
      }
      this.scrollPosition = false;
    },

    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1024) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
  },

  watch: {
    '$parent.language'(newLanguage) {
      this.initTitle = newLanguage ? 'Início' : 'Home';
      this.aboutTitle = newLanguage ? 'Sobre' : 'About';
      this.contactTitle = newLanguage ? 'Contato' : 'Contact';
      this.recomendationsTitle = newLanguage ? 'Comentários' : 'Feedbacks';
      this.experienceTitle = newLanguage ? 'Experiência' : 'Experience';
    },
  },
};
</script>


<style lang="scss" scoped>
.navbar__link-active {
  background-color: #a48fff48;
  color: #fff;
}

header {
  z-index: 99;
  width: 100%;
  position: fixed;
  transition: 0.5s ease all;
  color: #fff;
}

nav {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 12px 0;
  transition: 0.5 ease all;
  width: 90%;
  margin: 0 auto;

  @media (min-width: 1140px) {
    max-width: 1140px;
  }

  ul,
  .navbar__link {
    color: #fff;
    list-style: none;
    text-decoration: none;
    margin-left: 22px;
  }

  li {
    padding: 16px;
    margin-left: 16px;
  }

  .mobile-nav-enter-active,
  .mobile-nav-leave-active {
    transition: 1s ease all;
  }

  .mobile-nav-enter-from,
  .mobile-nav-leave-to {
    transform: translateX(-250px);
  }

  .mobile-nav-enter-to {
    transform: translateX(0);
  }

  .navbar__link {
    font-size: 14px;
    transition: 0.5s ease all;
    padding: 10px 14px;
    border-radius: 4px;
    border-bottom: 1px solid transparent;
    font-size: 16px;

    &:hover {
      color: #fff;
      background: #a48fff48;
    }
  }

  .navbar__branding {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #f6f6f6;

    img {
      width: 40px;
      transition: 0.5s ease all;
      border-radius: 50%;
    }
  }

  .navbar__navigation {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
  }

  .navbar__icon-mobile {
    display: flex;
    align-items: center;
    position: absolute;
    top: 24;
    right: 24px;
    height: 100%;
    margin-top: 20px;
    cursor: pointer;
    font-size: 24px;
    transition: 0.8s ease all;

    .bar {
      width: 25px;
      height: 2px;
      background-color: #fff;
      margin: 6px 0;
      transition: 0.4s;

      &.bar1 {
        transform: rotate(-45deg) translate(-5px, 6px);
      }

      &.bar2 {
        opacity: 0;
      }

      &.bar3 {
        transform: rotate(45deg) translate(-5px, -6px);
      }
    }
  }

  .navbar__dropdown-navigation {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    max-width: 250px;
    height: 100%;
    background: rgb(3, 0, 20);
    border: 1px solid #a48fff1f;
    color: #ffffff;
    backdrop-filter: blur(15px);
    top: 0;
    left: -24px;

    li {
      margin-top: 24px;
      margin-left: 0;
      display: flex;
      flex-direction: column;
    }

    .navbar__link {
      display: flex;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #a48fff1f;
    }
  }
}

.icon-active {
  .bar1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .bar2 {
    opacity: 0;
  }

  .bar3 {
      transform: rotate(45deg) translate(-5px, -6px);
    }
  }

.navbar__scrolled {
  background: rgb(3, 0, 20);
  border-bottom: 1px solid #a48fff1f;
}

a {
  border: none !important;
}
</style>