<template>
	<div class="badge__container">
		<p class="badge__text">{{ text }}</p>
	</div>
</template>

<script>
export default {
	props: {
		text: {
			type: String,
			default: ''
		}
	}
}
</script>

<style scoped lang="scss">
@import '../assets/styles/variables.scss';
.badge {
	&__container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: max-content;
		padding: 3px 12px;
		color: $white-10;
				background-color: rgba(145, 129, 235, 0.288);
				transition: all 0.4s ease 0s;
				border-radius: 28px;
				border: 1px solid #a48fff1f;

		p {
			font-size: .8rem;
		}
	}
}

@media screen and (max-width: 1024px) {
		p {
			font-size: .7rem !important;
		}

		.badge__container {
				padding: 2px 8px;
		}
}
</style>