export const translations = {
    en: {
      recommendations: [
        {
            avatar: 'https://github.com/edwildson.png',
            name: 'Edwildson Coelho',
            job: 'FullStack Developer',
            feedback: 'Highly recommend Hosana not only as an exceptional Frontend but also as a developer who showed extraordinary dedication by taking on backend roles as well. Her experience as a scrum master is a differentiator. Always delivering high-quality results.'
        },
        {
          avatar: 'https://github.com/ezequiasantunes.png',
          name: 'Ezequias Antunes',
          job: 'FullStack Developer',
          feedback: 'Hosana built creative and intuitive interfaces, materializing ideas into reality. Her communication, perception, and proactivity skills were essential to our team\'s success.'
        },
        {
          avatar: 'https://github.com/GabrielBernardoMC.png',
          name: 'Gabriel Bernardo',
          job: 'DevOps Analyst',
          feedback: 'A wonderful person to work with: helpful, attentive, hardworking, and very intelligent. I am very happy to have had the opportunity to work with someone like her and I hope that others have the same chance!'
        },
        {
          avatar: 'https://github.com/rodrigo-barboza.png',
          name: 'Rodrigo Barbosa',
          job: 'FullStack Developer',
          feedback: 'Playing a crucial role in creating a consistent and high-quality user experience. I have seen numerous compliments to Hosana for her proactive approach, exceptional teamwork, and impeccable professional ethics.'
        },
        {
            avatar: 'https://github.com/LeticiaLopess.png',
            name: 'Leticia Lopes',
            job: 'FullStack Developer',
            feedback: 'Her leadership ability and empathy with the team are inspiring, always seeking the growth and development of everyone. With her leading the team, we can achieve our goals more efficiently and harmoniously.'
        },
        {
            avatar: 'https://github.com/DevGabrielFeitosa.png',
            name: 'Gabriel Feitosa',
            job: 'FullStack Developer',
            feedback: 'Excellent professional, very serious and detailed in the functions performed, in addition to her creativity and critical vision of the product, she brought several improvements to our environment through her work and communication techniques.'
        },
        {
            avatar: 'https://github.com/danielleferreira.png',
            name: 'Danielle Ferreira',
            job: 'Product Owner',
            feedback: 'Her creativity and proactivity are remarkable, and she is always willing to help. With excellent technical skills and an impressive sense of organization, she excels in all her tasks. I can say that Hosana is dedicated and detail-oriented, always contributing to the growth of the team.'
        },
      ],

      experiences: [
        {
          jobTitle: 'Senior Software Developer',
          date: 'Dec/24-Present',
          description:
            'Software development for products related to the financial market, using Vue and Nuxt. Focus on front-end development and architecture, as well as technical decision-making while prioritizing best practices.',
          badges: ['Vue', 'Nuxt', 'Docker', 'Jest', 'Figma', 'Git',],
          image: 'https://media.licdn.com/dms/image/D4E0BAQHQ9eECBKxujA/company-logo_100_100/0/1696819628078?e=1715212800&v=beta&t=6sOmpyqeqez5-3uYvp8855hb6B0Cm5fI3NCLqcios4Y',
        },
        {
          jobTitle: 'Team Leader',
          date: 'Dec/23-Present',
          description:
            'Mapping new product strategies, creating backlog stories, monitoring and updating activity progress, and refining tasks using the Scrum framework with a focus on agility. Identifying system improvements alongside the client and developing software with JavaScript frameworks.',
          badges: ['Jira', 'Scrum', 'Kanban', 'Atlassian', 'Figma', 'Vue', 'Nuxt', 'React', 'Cypress'],
          image: 'https://solutionsa.com.br/img/favicon.svg',
        },
        {
            jobTitle: 'Software Developer',
            date: 'Sep/23-Dec/24',
            description:
              'Software development for diverse products with a focus on front-end, using Nuxt and Vue, including the development of unit tests, integration tests, and E2E tests using Jest and Cypress.',
            badges: ['Vue', 'Nuxt', 'Docker', 'Jest', 'Cypress', 'Sass', 'Figma', 'Git', 'React'],
            image: 'https://media.licdn.com/dms/image/D4E0BAQHQ9eECBKxujA/company-logo_100_100/0/1696819628078?e=1715212800&v=beta&t=6sOmpyqeqez5-3uYvp8855hb6B0Cm5fI3NCLqcios4Y',
        },
        {
            jobTitle: 'Software Development Analyst',
            date: 'Sep/23-Mar/24',
            description:
              'Front-end software development for the financial market, using React and Vue, performing automated, unit, and integration tests using Jest or Vitest.',
            badges: ['React', 'Vue', 'Vitest', 'Jest'],
            image: 'https://www.cma.com.br/wp-content/uploads/2024/01/logo-cma.png',
        },
        {
          jobTitle: 'Software Development Analyst',
          date: 'Feb/23-Jul/23',
          description:
            'Front-end software development for the healthcare sector, using the Vue framework; also acting as a Scrum Master during agility formation. Active participation in UX activities involved with the company\'s Design System.',
          badges: ['Vue', 'Sass', 'Jest', 'Scrum', 'Docker', 'Git'],
          image: 'https://avatars.githubusercontent.com/u/11979180?s=200&v=4',
        },
        {
          jobTitle: 'Software Development Intern',
          date: 'Nov/2021-Jan/2023',
          description:
            'Software development according to user stories. Execution of backlog tasks, respecting the prioritization defined by the Product Owner. Main activities developed: Front-end development with Vue, responsive layouts, and unit tests with Jest.',
          badges: ['Vue', 'Sass', 'Jest', 'Scrum', 'Git'],
          image: 'https://avatars.githubusercontent.com/u/11979180?s=200&v=4',
        },
      ],
    },
    pt: {
      recommendations: [
        {
          avatar: 'https://github.com/edwildson.png',
          name: 'Edwildson Coelho',
          job: 'Desenvolvedor FullStack',
          feedback: 'Recomendo muito Hosana não apenas como Frontend excepcional mas sim como uma pessoa desenvolvedora, que demonstrou dedicação extraordinária ao também assumir funções no backend. Sua experiência como scrum master é um diferencial. Sempre entregando resultados de alta qualidade.'
        },
        {
          avatar: 'https://github.com/ezequiasantunes.png',
          name: 'Ezequias Antunes',
          job: 'Desenvolvedor FullStack',
          feedback: 'Hosana construiu interfaces criativas e intuitivas, materializando ideias em realidade. Suas habilidades de comunicação, percepção e proatividade foram essenciais para o sucesso de nosso time.'
        },
        {
          avatar: 'https://github.com/GabrielBernardoMC.png',
          name: 'Gabriel Bernardo',
          job: 'Analista DevOps',
          feedback: 'Uma pessoa maravilhosa de se conviver: prestativa, atenciosa, esforçada e muito inteligente. Fico muito feliz por ter tido a oportunidade de trabalhar com alguém assim e torço pra que outras pessoas tenham a mesma chance!'
        },
        {
          avatar: 'https://github.com/rodrigo-barboza.png',
          name: 'Rodrigo Barbosa',
          job: 'Desenvolvedor FullStack',
          feedback: 'Desempenhou um papel crucial na criação de uma experiência de usuário consistente e de alta qualidade. Tenho visto inúmeros elogios a Hosana por sua abordagem proativa, trabalho em equipe excepcional e ética profissional impecável.'
        },
        {
            avatar: 'https://github.com/LeticiaLopess.png',
            name: 'Leticia Lopes',
            job: 'Desenvolvedora FullStack',
            feedback: 'Sua capacidade de liderança e empatia com a equipe são inspiradoras, sempre buscando o crescimento e desenvolvimento de todos. Com ela à frente do time, conseguimos alcançar as metas de maneira mais eficiente e harmoniosa.'
        },
        {
            avatar: 'https://github.com/DevGabrielFeitosa.png',
            name: 'Gabriel Feitosa',
            job: 'Desenvolvedor FullStack',
            feedback: 'Excelente profissional, muito séria e detalhista nas funções desempenhadas, além de sua criatividade e visão crítica do produto, trouxe diversas melhorias ao nosso ambiente através de suas técnicas de trabalho e comunicação.'
        },
        {
            avatar: 'https://github.com/danielleferreira.png',
            name: 'Danielle Ferreira',
            job: 'Product Owner',
            feedback: 'Sua criatividade e proatividade são notáveis, e ela está sempre disposta a ajudar. Com uma excelente capacidade técnica e um impressionante senso de organização, ela se destaca em todas as suas tarefas. Posso afirmar que Hosana é dedicada e detalhista, sempre contribuindo para o crescimento do time.'
        },
      ],

      experiences: [
        {
          jobTitle: 'Desenvolvedora de Software Sênior',
          date: 'Dez/24-Atual',
          description:
            'Desenvolvimento de software para produtos ligados ao mercado financeiro, usando Vue e Nuxt. Foco em desenvolvimento e arquitetura front-end com tomadas de decisões técnicas prezando boas práticas.',
          badges: ['Vue', 'Nuxt', 'Docker', 'Jest', 'Figma', 'Git',],
          image: 'https://media.licdn.com/dms/image/D4E0BAQHQ9eECBKxujA/company-logo_100_100/0/1696819628078?e=1715212800&v=beta&t=6sOmpyqeqez5-3uYvp8855hb6B0Cm5fI3NCLqcios4Y',
        },
        {
          jobTitle: 'Team Leader',
          date: 'Dez/23-Atual',
          description:
            'Mapeamento de novas estratégias do produto, criação de histórias de backlog, verificação e atualização de andamento das atividades e refinamento de atividades utilizando o framework Scrum, com foco em agilidade. Identificação de melhorias nos sistemas ao lado do cliente e desenvolvimento de software com frameworks JavaScript.',
          badges: ['Jira', 'Scrum', 'Kanban', 'Atlassian', 'Figma', 'Vue', 'Nuxt', 'React', 'Cypress'],
          image: 'https://solutionsa.com.br/img/favicon.svg',
        },
        {
            jobTitle: 'Desenvolvedora de Software',
            date: 'Set/23-Dez/24',
            description:
              'Desenvolvimento de software para produtos diversos com ênfase em front-end, usando Nuxt e Vue com desenvolvimento de testes unitários, de integração e E2E utilizando Jest e Cypress.',
            badges: ['Nuxt', 'Vue', 'Docker', 'Jest', 'Cypress', 'Sass', 'Figma', 'Git', 'React'],
            image: 'https://media.licdn.com/dms/image/D4E0BAQHQ9eECBKxujA/company-logo_100_100/0/1696819628078?e=1715212800&v=beta&t=6sOmpyqeqez5-3uYvp8855hb6B0Cm5fI3NCLqcios4Y',
          },
        {
          jobTitle: 'Analista de Desenvolvimento de Software',
          date: 'Set/23-Mar/24',
          description:
            'Desenvolvimento de software focado em front-end para o mercado financeiro, usando React e Vue realizando testes automatizados, unitários e de integração utilizando Jest ou Vitest.',
          badges: ['React', 'Vue', 'Vitest', 'Jest'],
          image: 'https://www.cma.com.br/wp-content/uploads/2024/01/logo-cma.png',
        },
        {
          jobTitle: 'Analista de Desenvolvimento de Software',
          date: 'Fev/23-Jul/23',
          description:
            'Desenvolvimento de software focado em front-end para a área da saúde, usando o framework Vue; atuando também como Scrum Master durante a formação de agilidade. Participação ativa nas atividades de UX envolvidas com o Design System da empresa.',
          badges: ['Vue', 'Sass', 'Jest', 'Scrum', 'Docker', 'Git'],
          image: 'https://avatars.githubusercontent.com/u/11979180?s=200&v=4',
        },
        {
          jobTitle: 'Estágio em Desenvolvimento de Software',
          date: 'Nov/21-Jan/23',
          description:
            'Desenvolvimento de software, de acordo com as histórias do usuário. Execução de tarefas do backlog, respeitando a priorização definida pelo Product Owner. Principais atividades desenvolvidas: Desenvolvimento Front-end com Vue, layouts responsivos e testes unitários com Jest.',
          badges: ['Vue', 'Sass', 'Jest', 'Scrum', 'Git'],
          image: 'https://avatars.githubusercontent.com/u/11979180?s=200&v=4',
        },
      ],
    },
  };