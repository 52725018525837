<template>
  <div class="recomendations__container">
    <div class="global-image">
      <img src="../../public/love.png" />
    </div>
    <h1>{{ title }}</h1>
    <div class="recomendations__subtitle">
        <h4>{{ description }}</h4>
        <div class="arrow">
            <span></span>
            <span></span>
        </div>
    </div>
    <div
      class="recomendations__container-min"
      @mouseover="pauseAnimation"
      @mouseleave="resumeAnimation"
    >
      <div
        class="recomendations__container-row animate-right"
        ref="rowRight"
      >
      <div class="blur"></div>
        <div
          class="recomendations__content"
          v-for="(recomendation, index) in firstRow"
          :key="index"
        >
          <div class="recomendations__content__header">
            <img
              :src="isLoading ? placeholderUrl :recomendation.avatar"
              @load="handleLoad"
              @error="handleError"
            />
            <div>
              <h3>{{ recomendation.name }}</h3>
              <span>{{ recomendation.job }}</span>
            </div>
          </div>
          <div class="feedback">
            <p>{{ recomendation.feedback }}</p>
          </div>
        </div>
      </div>
      <div
        class="recomendations__container-row animate-left"
        ref="rowLeft"
        @mousedown="startDraggingLeft"
        @touchstart="startDraggingLeft"
      >
        <div
          class="recomendations__content"
          v-for="(recomendation, index) in secondRow"
          :key="index"
        >
          <div class="recomendations__content__header">
            <img
              :src="isLoading ? placeholderUrl :recomendation.avatar"
              @load="handleLoad"
              @error="handleError"
            />
            <div>
              <h3>{{ recomendation.name }}</h3>
              <span>{{ recomendation.job }}</span>
            </div>
          </div>
          <div class="feedback">
            <p>{{ recomendation.feedback }}</p>
          </div>
        </div>
        <a class="ca3-scroll-down-link ca3-scroll-down-arrow" data-ca3_iconfont="ETmodules" data-ca3_icon=""></a>
      </div>
    </div>
  </div>
</template>

  <script>
import { mapState } from "vuex";
import { translations } from "@/utils/recomendations";

export default {
  data() {
    return {
      placeholderUrl: 'https://static.vecteezy.com/system/resources/thumbnails/024/406/396/small_2x/purple-planet-space-png.png',
      isLoading: true,
    };
  },
  computed: {
    ...mapState(["language"]),
    translatedRecomendations() {
      return this.language
        ? translations.pt.recommendations
        : translations.en.recommendations;
    },
    firstRow() {
      return this.translatedRecomendations.slice(0, 4);
    },
    secondRow() {
      return this.translatedRecomendations.slice(4, 7);
    },
    description() {
      return this.language
        ? "Veja o que as pessoas estão dizendo sobre mim!"
        : "Here’s what people are saying about me!";
    },
    title() {
      return this.language ? "Parceiros de trabalho" : "Working partners";
    },
  },
  methods: {
    handleLoad() {
      this.isLoading = false;
    },
    handleError(event) {
      event.target.src = this.placeholderUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.recomendations__container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  position: relative;
  overflow: hidden;


  &::before {
    /*background: linear-gradient(
      270deg,
      #030014 0%,
      rgba(3, 0, 20, 0.5) 16.67%,
      rgba(3, 0, 20, 0) 33.33%,
      rgba(3, 0, 20, 0) 50%,
      rgba(3, 0, 20, 0) 66.67%,
      rgba(3, 0, 20, 0.5) 83.33%,
      #030014 100%
    );*/
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 200%;
    z-index: 1;
    pointer-events: none;
    transform: translateX(-50%);
  }

  .stars {
    font-size: 18px;
  }
}

h1 {
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.7) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  display: block;
  font-size: 2rem;
}

h3 {
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.7) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  display: block;
  font-size: 1.5rem;
  margin-bottom: 16px;
}

h4 {
  color: #efedfdb3;
  margin: 12px auto 0;
  max-width: 455px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 18px;
}

.global-image {
  position: absolute;
  bottom: -120px;
  right: -90px;
  z-index: -2;

  img {
    height: 390px;
  }
}

.recomendations__container-min {
  margin: 0 auto;
  max-width: 70%;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  z-index: 5;
}

.recomendations__container-row {
  display: flex;
  margin-bottom: 24px;
  width: -moz-max-content;
  width: max-content;
  flex-shrink: 0;
  margin-left: 2rem;
  margin-right: 2rem;
}

.recomendations__container-min::before {
  content: "";
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.recomendations__content {
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  background: radial-gradient(
      57.89% 132% at 65.79% -35%,
      rgba(120, 123, 255, 0.06) 0%,
      rgba(120, 123, 255, 0) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 54.17%,
      rgba(255, 255, 255, 0.04) 100%
    ),
    rgba(255, 255, 255, 0.01);
  border-radius: 16px;
  padding: 24px 28px;
  position: relative;
  width: 380px;
  margin: 12px;
  height: 220px;

  h2 {
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 24px;
  }

  img {
    width: 32px;
    border-radius: 50%;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;

    h3 {
      font-size: 16px;
    }

    span {
      margin-top: -18px;
      font-size: 12px;
    }
  }
}

.recomendations__content::before {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.04) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04));
  border-radius: inherit;
  content: "";
  inset: 0;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: xor;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  padding: 1px;
  pointer-events: none;
  position: absolute;
}

.feedback {
    p {
        font-size: .9rem;
    }
}

.box {
  position: absolute;
  top: 15%;
  left: 75%;
}

.blur {
    /* background: linear-gradient(
  90deg,
  rgba(3, 0, 20, 0) 0%,
  rgba(3, 0, 20, 0) 50%,
  rgba(3, 0, 20, 0.5) 83.33%,
  #030014 100%
); */
    width: 5rem;
    height: 34rem;
    z-index: 99;
    position: fixed;
    right: 0;
    margin-right: 18rem;

}

@keyframes animateRowLeft {
  0% {
    transform: translateZ(0) translate(0);
  }
  100% {
    transform: translateZ(0) translate(calc(-40% - 12px));
  }
}

@keyframes animateRowRight {
  0% {
    transform: translateZ(0) translate(calc(-40% - 12px));
  }
  100% {
    transform: translateZ(0) translate(0);
  }
}

/* .animate-left {
  animation: animateRowLeft 30s linear infinite;
}

.animate-right {
  animation: animateRowRight 30s linear infinite;
} */

//animated arrow
.recomendations__subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    h4 {
        margin-right: 1rem
    }
}
.arrow {
    margin-top: -.9rem;
    transform: translate(-50%, -50%);
    transform: rotate(270deg);
}

.arrow span {
    display: block;
    width: .6rem;
    height: .6rem;
    border-bottom: 2px solid rgba(154, 98, 218, 0.712);
    border-right: 2px solid rgba(154, 98, 218, 0.185);
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
    animation-delay: -0.4s;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}

@media screen and (max-width: 768px) {

    .recomendations__subtitle {
    margin-left: 2rem;
}

    .arrow {
    margin-top: 3px;
}

  h1 {
    font-size: 22px;
  }
  h4 {
    font-size: 14px !important;
    margin-bottom: 1px;
  }
  .recomendations__container-min {
    max-width: 100%;
    margin: 16px;
  }
  .recomendations__content {
    width: 250px;
    padding: 16px 20px;
    margin: 8px;

    p {
      font-size: 12px;
    }

    h3 {
      font-size: 14px;
      margin-bottom: 4px;
    }

    img {
      width: 24px;
    }

    &__header {
      gap: 8px;
      margin-bottom: 12px;

      span {
        font-size: 12px;
        margin-top: -5px;
      }
    }
  }

  /* .animate-left {
  animation: animateRowLeft 75s linear infinite;
}

.animate-right {
  animation: animateRowRight 75s linear infinite;
} */

  .recomendations__container-min::before {
  }
  /*@keyframes animateRowLeft {
    0% {
      transform: translateZ(0) translate(0);
    }
    100% {
      transform: translateZ(0) translate(calc(-35% - 12px));
    }
  }

  @keyframes animateRowRight {
    0% {
      transform: translateZ(0) translate(calc(-35% - 12px));
    }
    100% {
      transform: translateZ(0) translate(0);
    }
  }*/

  .global-image {
    img {
      height: 280px;
    }
  }
}


</style>
